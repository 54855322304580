import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
// import { ParseLazyBlocks } from '../../parser'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'
import TrackVisibility from 'react-on-screen'
import PromotionBannerAnimation from '../components/PromotionBannerAnimation'

interface Props {
  data: any
  pageContext: any
}
const ServicesTemplate = ({ data, pageContext }: Props) => {
 
  const { wpService } = data
  // console.log(wpService)

  const renderedBlocks = LazyBlocksFactory(wpService.lazy_data, wpService.title)
  // console.log(lazyBlocks)
  // console.log(renderedBlocks)

  return (
    <Layout>
      {renderedBlocks.map((v) => v)}

     
    </Layout>
  )
  // return <Layout>hi</Layout>
}

export default ServicesTemplate

export const pageQuery = graphql`
  query ServiceById($id: String!) {
    wpService(id: { eq: $id }) {
      title
      id
      lazy_data
    }
  }
`
